import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import si from "../../utils/si";
import {toast} from "react-toastify";


export const getGameDemoUrl = createAsyncThunk(
  'getGameDemoUrl',
  async (pr, gn, ln) => {
    const bodyData = {
      ti: Math.floor(Date.now() / 1000),
      pr,
      gn,
      ru: encodeURIComponent(window.location.href),
      ...(ln && {ln}),
    };
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('getgamedemourl', body);
    return data;

  }
);

export const getGameUrl = createAsyncThunk(
  'getGameUrl',
  async (params) => {
    const bodyData = {
      pr: params.pr,
      to: localStorage.getItem('to'),
      gn: params.gn,
      ip: params.ip,
      ru: encodeURIComponent(window.location.href),
      du: encodeURIComponent(`${window.location.href}/deposit`),
      ti: Math.floor(Date.now() / 1000),
    };
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('getgameurl', body);
    return data;
  }
);

export const addFavoriteGame = createAsyncThunk(
  'addFavoriteGame',
  async (params, {rejectWithValue}) => {
    try {
      const bodyData = {
        pr: params.pr,
        to: localStorage.getItem('to'),
        gn: params.gn,
        ti: Math.floor(Date.now() / 1000),
      };
      const body = JSON.stringify({...bodyData, si: si(bodyData)})
      const {data} = await axiosInstance.post('add_favorite_game', body);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const delFavoriteGame = createAsyncThunk(
  'delFavoriteGame',
  async (params, {rejectWithValue}) => {
    try {
      const bodyData = {
        pr: params.pr,
        to: localStorage.getItem('to'),
        gn: params.gn,
        ti: Math.floor(Date.now() / 1000),
      };
      const body = JSON.stringify({...bodyData, si: si(bodyData)})
      const {data} = await axiosInstance.post('delete_favorite_game', body);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  gameDemoUrl: null,
  gameUrl: null,
  favoriteGame: null,
  onSuccess: null,
  errors: null,
  loaders: {
    common: false,
    editProfile: false,
    changePassword: false,
  },
};


const gamesSlice = createSlice({
  name: 'games',
  initialState,

  extraReducers: (builder) => {
    //getGameDemoUrl
    builder.addCase(getGameDemoUrl.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getGameDemoUrl.fulfilled, (state, {payload}) => {
      state.gameDemoUrl = payload;
      state.onSuccess = true;
      state.loaders.common = false;
      if (payload.ec) {
        toast.error('Произошла ошибка. Попробуйте позже или свяжитесь с тех поддержкой', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
    builder.addCase(getGameDemoUrl.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
    //getGameUrl
    builder.addCase(getGameUrl.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getGameUrl.fulfilled, (state, {payload}) => {
      state.gameUrl = payload;
      state.onSuccess = true;
      state.loaders.common = false;
      if (payload.ec) {
        toast.error('Произошла ошибка. Попробуйте позже или свяжитесь с тех поддержкой', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
    builder.addCase(getGameUrl.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
    //delFavoriteGame
    builder.addCase(delFavoriteGame.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(delFavoriteGame.fulfilled, (state, {payload}) => {
      state.favoriteGame = payload;
      state.onSuccess = true;
      state.loaders.common = false;

    });
    builder.addCase(delFavoriteGame.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
    //addFavoriteGame
    builder.addCase(addFavoriteGame.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(addFavoriteGame.fulfilled, (state, {payload}) => {
      state.favoriteGame = payload;
      state.onSuccess = true;
      state.loaders.common = false;
    });
    builder.addCase(addFavoriteGame.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
  },
});

export default gamesSlice.reducer;
