import {useDispatch} from "react-redux";
import React, {useState} from "react";
import {Controller, useForm} from 'react-hook-form';
import {auth} from "../../store/auth";
import Hide from "../../assets/icons/Hide";
import Show from "../../assets/icons/Show";
import styles from "./SignIn.module.css"

// @ts-ignore
const SignIn = () => {
  const dispatch = useDispatch();
  const {control, handleSubmit} = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (data) => {
    dispatch(auth(data))
  };


  return (
    <div className='wrapperForm'>
      <form className='form' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>Логин:</label>
          <Controller
            name="lo"
            control={control}
            defaultValue=""
            render={({field}) => <input className='input' {...field} />}
          />
        </div>
        <div>
          <label>Пароль:</label>
          <Controller
            name='ps'
            control={control}
            defaultValue=''
            render={({field}) => (
              <div className={styles.wrapperInput}>
                <input className='input' type={showPassword ? 'text' : 'password'} {...field} />
                <div className={styles.icon}
                     onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Show/> : <Hide/>}
                </div>
              </div>
            )}
          />
        </div>
        <button style={{width: '100%'}} className='btn' type="submit">Войти</button>
      </form>
    </div>
  );
};

export default SignIn;