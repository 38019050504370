import React from 'react';

const Show = ({width, height}) => {
  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M8 11.3335C6.13333 11.3335 4.66667 9.86683 4.66667 8.00016C4.66667 6.1335 6.13333 4.66683 8 4.66683C9.86667 4.66683 11.3333 6.1335 11.3333 8.00016C11.3333 8.20016 11.3333 8.46683 11.2666 8.66683C11.7999 8.46683 12.4 8.3335 13 8.3335C14 8.3335 14.8667 8.66684 15.6667 9.1335C15.6939 9.05186 15.7211 8.973 15.7477 8.89578L15.7478 8.89572C15.8517 8.59462 15.9469 8.3185 16 8.00016C15.0667 4.1335 11.8667 1.3335 8 1.3335C4.13333 1.3335 0.933333 4.1335 0 8.00016C0.933333 11.8668 4.13333 14.6668 8 14.6668H8.66667C8.46667 14.1335 8.33333 13.6002 8.33333 13.0002C8.33333 12.4002 8.46667 11.8002 8.66667 11.2668C8.46672 11.3335 8.20013 11.3335 8.00015 11.3335H8ZM8 9.3335C8.73638 9.3335 9.33333 8.73654 9.33333 8.00016C9.33333 7.26378 8.73638 6.66683 8 6.66683C7.26362 6.66683 6.66667 7.26378 6.66667 8.00016C6.66667 8.73654 7.26362 9.3335 8 9.3335ZM13 11.6668L14.6667 10.0002L16 11.3335L14.3333 13.0002L16 14.6668L14.6667 16.0002L13 14.3335L11.3333 16.0002L10 14.6668L11.6667 13.0002L10 11.3335L11.3333 10.0002L13 11.6668Z"
            fill="#EBBB6E"/>
    </svg>
  );
};

export default Show;