import React from "react";
import {logout} from "../../store/auth";
import Logo from "../../assets/Logo";
import {useDispatch, useSelector} from "react-redux";
import {getIsLoggedIn} from "../../store/auth/selectors";

// @ts-ignore
const Header = () => {
    const dispatch = useDispatch();
    const IsLoggedIn = useSelector(getIsLoggedIn)
    return (
      <header className='wrapper'>
          <div className='header'>
              <Logo height={'40px'} width={'200px'}/>
              {IsLoggedIn && <button onClick={()=>dispatch(logout())} className='btn'>Выйти</button>}
          </div>
      </header>
    );
};

export default Header;