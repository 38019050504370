
export const gameList = ({ gameList }) => gameList.gamesList;

export const lastGames = ({ gameList }) => gameList.lastGames;

export const favoritesList = ({ gameList }) => gameList.favoritesList;

export const recommendedList = ({ gameList }) => gameList.recommendedGames;

export const isLoadingList = ({ gameList }) => gameList.loaders

export const getGamesSearchQuery = ({ gameList }) => gameList.searchQuery;

export const getSearchResult = ({ gameList }) => gameList.searchResult;