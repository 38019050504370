import React from "react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import styles from './GameCard.module.css';
import {gamePicPath} from "../../utils/imgPath";
import {getGameUrl} from "../../store/games";

const GameCard = ({name, ip, pr}) => {
    const dispatch = useDispatch();
    const handleStartGame = () => {
        const gn = name
        dispatch(getGameUrl({pr, gn, ip})).then(
            (data) => {
                if (data.payload.ec) {
                    toast.error(
                        "Произошла ошибка. Попробуйте позже или свяжитесь с тех поддержкой",
                        {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        }
                    );
                } else {
                    window.location.href = data.payload.href;
                }
            }
        );
    }

    return (
      <div
        className={styles.wrapper}
        onClick={handleStartGame}
        style={{
          backgroundImage: `url(${gamePicPath}${pr}/${name}.png)`,
        }}
      />
    );
};

export default GameCard;