import Base64 from "crypto-js/enc-base64";
import MD5 from "crypto-js/md5";

const {REACT_APP_SIGN_KEY} = process.env

const si = data => {
    return Base64.stringify(MD5(`${JSON.stringify(data)}${REACT_APP_SIGN_KEY}`));
}

export default si;
