import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {getUserData, refreshToken} from "./store/auth";
import {getIsLoggedIn} from "./store/auth/selectors";
import {getGamesList} from "./store/gameList";
import {getMyIp} from "./store/ip";
import SignIn from "./component/SignIn";
import Header from "./component/Header";
import GameList from "./component/GameList";


function App() {
  const dispatch = useDispatch();
  const to = localStorage.getItem('to');
  const entireState = useSelector((state) => state);
  const IsLoggedIn = useSelector(getIsLoggedIn)


  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const deadlineToken = Number(localStorage.getItem('ttl'));
    const timeUntilExpiration = deadlineToken - currentTime;
    const fiveMinutes = 5 * 60; // 5 минут в секундах
    if (timeUntilExpiration <= fiveMinutes && to) {
      // Обновление токена
      console.log('попытка обновления токена')
      dispatch(refreshToken())
    }
  }, [entireState]);

  useEffect(() => {
    dispatch(getMyIp())
    dispatch(getGamesList())
    dispatch(getUserData());
  }, []);

  return (<>
      <Header/>
      <content>
        {IsLoggedIn ? <GameList/> : <SignIn/>}
      </content>
    </>
  );
}

export default App;
