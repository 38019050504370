//ts-nocheck
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import CryptoJS from 'crypto-js';
import si from "../../utils/si";

const hashPassword = (password) => {
    return CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
};

export const auth = createAsyncThunk(
    'auth',
    async (params) => {
        const { ps, ...restParams } = params; // Извлекаем пароль и оставляем остальные параметры
        const hashedPassword = hashPassword(ps);
        const bodyData = { ...restParams, ps: hashedPassword, ti: Math.floor(Date.now() / 1000) };
        const body = JSON.stringify({...bodyData, si: si(bodyData)})
        const {data} = await axiosInstance.post('authuser', body);
        return data;
    }
);

export const reg = createAsyncThunk(
    'reg',
    async (params) => {
        console.log('params', params)
        const bodyData = {...params.data, ti: Math.floor(Date.now() / 1000)};
        const body = JSON.stringify({...bodyData, si: si(bodyData)})
        const {data} = await axiosInstance.post('registeruser', body);
        return data;
    }
);

export const logout = createAsyncThunk('logout', async () => {
    const bodyData = {ti: Math.floor(Date.now() / 1000), to: localStorage.getItem('to')};
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('logout', body);
    return data;
});

export const tgsendcode = createAsyncThunk('tgsendcode', async (tn, {rejectWithValue}) => {
    const usernameRegex = /^@[A-Za-z0-9]+$/;
    const bodyData = {tn: tn, ti: Math.floor(Date.now() / 1000)};
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    if (usernameRegex.test(tn)) {
        try {
            const {data} = await axiosInstance.post('tgsendcode', body);
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    } else return rejectWithValue({msg: "Только английские буквы и цифры."});

});

export const tgverifycode = createAsyncThunk('tgverifycode', async (params, {rejectWithValue}) => {
    const bodyData = {tn: params.tn, cd: params.cd, ti: Math.floor(Date.now() / 1000)};
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    try {
        const {data} = await axiosInstance.post('tgverifycode', body);
        return data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const getUserData = createAsyncThunk('getUserData', async () => {
    const bodyData = {ti: Math.floor(Date.now() / 1000), to: localStorage.getItem('to')};
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('getuserdata', body);
    return data;
});

export const setUserData = createAsyncThunk(
    'setUserData',
    async (params) => {
        const bodyData = {...params, ti: Math.floor(Date.now() / 1000), to: localStorage.getItem('to')};
        Object.keys(bodyData).forEach(key => {
            if (bodyData[key] === null || bodyData[key] === '' || bodyData[key] === undefined) {
                delete bodyData[key];
            }
        });
        const body = JSON.stringify({...bodyData, si: si(bodyData)})
        const {data} = await axiosInstance.post('setuserdata', body);
        return data;
    }
);

export const refreshToken = createAsyncThunk(
    'refreshToken',
    async () => {
        const bodyData = {ti: Math.floor(Date.now() / 1000), to: localStorage.getItem('to')};
        const body = JSON.stringify({...bodyData, si: si(bodyData)})
        const {data} = await axiosInstance.post('refreshToken', body);
        return data;
    }
);

export const getCurrency = createAsyncThunk('getCurrency', async () => {
    const bodyData = {ti: Math.floor(Date.now() / 1000)};
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('getcurrencys', body);
    return data;
});

export const getLangs = createAsyncThunk('getLangs', async () => {
    const bodyData = {ti: Math.floor(Date.now() / 1000)};
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('getlangs', body);
    return data;
});

const initialState = {
    user: null,
    isAuthenticated: false,
    currency: [],
    langs: [],
    isLoggedIn: false,
    onSuccess: null,
    timeForTimer: 0,
    errors: null,
    errorTg: null,
    errorCd: null,
    email: null,
    loaders: {
        common: false,
        editProfile: false,
        changePassword: false,
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setError1Step: (state) => {
            state.errorTg = null;
        },
        setError2Step: (state) => {
            state.errorCd = null;
        }
    },
    extraReducers: (builder) => {
        //auth
        builder.addCase(auth.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(auth.fulfilled, (state, {payload}) => {
            if (payload.ec) {
                state.errors = payload.ec;
            } else {
                state.user = payload;
                state.isLoggedIn = true
                state.onSuccess = true;
                localStorage.setItem('to', payload.to);
                localStorage.setItem('ttl', payload.ttl);
                state.loaders.common = false;
            }
        });
        //reg
        builder.addCase(reg.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(reg.fulfilled, (state, {payload}) => {
            if (payload.ec) {
                state.errors = payload.ec;
            } else {
                state.onSuccess = true;
                localStorage.setItem('to', payload.to);
                localStorage.setItem('ttl', payload.ttl);
                state.loaders.common = false;
            }
        });
        //refreshToken
        builder.addCase(refreshToken.pending, () => {
        });
        builder.addCase(refreshToken.fulfilled, (state, {payload}) => {
            if (payload.to) {
                console.log('Token обновился')
                localStorage.setItem('to', payload.to);
                localStorage.setItem('ttl', payload.ttl);
            } else {
                console.log('Token сгорел')
                state.user = null;
                state.isLoggedIn = false
                localStorage.removeItem('to');
                localStorage.removeItem('ttl');
                state.isAuthenticated = false;
            }
        });
        //getUserData
        builder.addCase(getUserData.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getUserData.fulfilled, (state, action) => {
            if (action.payload.ec) {
                state.errors = action.payload.ec;
            } else {
                state.user = action.payload;
                state.isLoggedIn = true
                state.onSuccess = true;
                localStorage.setItem('to', action.payload.to);
                localStorage.setItem('ttl', action.payload.ttl);
                state.loaders.common = false;
            }
        });
        //setUserData
        builder.addCase(setUserData.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(setUserData.fulfilled, (state, action) => {
            if (action.payload.ec) {
                state.errors = action.payload.ec;
            } else {
                state.user = action.payload;
                localStorage.setItem('to', action.payload.to);
                localStorage.setItem('ttl', action.payload.ttl);
                state.isAuthenticated = true;
            }
        });
        //logout
        builder.addCase(logout.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(logout.fulfilled, (state, action) => {
            state.user = null;
            state.isLoggedIn = false
            localStorage.removeItem('to');
            localStorage.removeItem('ttl');
            state.isAuthenticated = false;
        });
        //getCurrency
        builder.addCase(getCurrency.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getCurrency.fulfilled, (state, {payload}) => {
            state.onSuccess = true;
            state.currency = payload.data;
            state.loaders.common = false;
        });
        //tgsendcode
        builder.addCase(tgsendcode.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(tgsendcode.fulfilled, (state, {payload}) => {
            state.timeForTimer = payload.ttl-payload.ti
            state.onSuccess = true;
            state.loaders.common = false;
        });
        builder.addCase(tgsendcode.rejected, (state, {payload}) => {
            state.errorTg = payload.msg;
        });
        //tgverifycode
        builder.addCase(tgverifycode.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(tgverifycode.fulfilled, (state, {payload}) => {
            state.user = payload;
            state.isLoggedIn = true
            state.onSuccess = true;
            localStorage.setItem('to', payload.to);
            localStorage.setItem('ttl', payload.ttl);
            state.loaders.common = false;
        });
        builder.addCase(tgverifycode.rejected, (state, {payload}) => {
            state.errorCd = payload.msg;
        });
        //getLangs
        builder.addCase(getLangs.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getLangs.fulfilled, (state, {payload}) => {
            state.onSuccess = true;
            state.langs = payload.data;
            state.loaders.common = false;
        });
    },
});

export const {setError1Step, setError2Step} = authSlice.actions;

export default authSlice.reducer;
